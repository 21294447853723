import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { GatsbyImage } from 'gatsby-plugin-image'

import { BREAKPOINTS, LAYOUT } from '../../styles/cssVariables'
import Container from '../layout/container'
import sun from '../../images/backgrounds/Ellipse.png'
import LogoGrid from '../../components/logoGrid'

const Wrapper = styled.section`
  position: relative;
  padding: 85px 0 65px;
  background: linear-gradient(180deg, rgba(0, 97, 99, 0.45) 0%, rgba(255, 252, 250, 0) 100%);  
  margin-bottom: 95px;
  @media (max-width: ${BREAKPOINTS.med}px) {
    padding-top: 35px;
    padding-bottom: 35px;
  }
  @media (max-width: ${BREAKPOINTS.sml}px) {
    padding-bottom: 0;
  }
 
  
`

const SecondaryHeadingContainer = styled(Container)`
@media (max-width: 616px) {
  padding: 0 30px;
}
@media (max-width: 478px) {
  margin-top: -60px;
}
 > div {
   flex-direction: column;
   justify-content: center;
   background-image: url('${sun}');
   background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
   
 }
`

const SecondaryHeading = styled.h2`
  text-align: center;
  z-index: 998;
  width: 70%;
  padding-top: 90px;
  @media (max-width: 1366px) {
    padding-top: 75px;
    width: 60%;
  }
  @media (max-width: 1219px) {
    padding-top: 60px;
  }
  @media (max-width: 1116px) {
    padding-top: 50px;
    width: 70%;
  }
  @media (max-width: 991px) {
    width: 80%;
  }
  @media (max-width: 869px) {
    padding-top: 70px;
    width: 100%;
  }
  @media (max-width: 770px) {
    padding-top: 130px;
  }
  @media (max-width: 616px) {
    padding-top: 50px;
  }
  @media (max-width: 478px) {
    padding-top: 140px;
  }
  @media (max-width: 431px) {
    padding-top: 130px;
  }
`
const SecondaryBody = styled.p`
  padding-top: 30px;
  padding-bottom: 90px;
  text-align: center;
  z-index: 997;
  width: 50%;
  @media (max-width: 1366px) {
    padding-bottom: 75px;
  }
  @media (max-width: 1219px) {
    padding-bottom: 60px;
  }
  @media (max-width: 1116px) {
    padding-bottom: 50px;
  }
  @media (max-width: 1080px) {
    width: 70%;
  }
  @media (max-width: 869px) {
    padding-bottom: 70px;
    width: 100%;
  }
  @media (max-width: 770px) {
    padding-bottom: 130px;
 
  }
  @media (max-width: 616px) {
    padding-bottom: 50px;
  }
  @media (max-width: ${BREAKPOINTS.sml}px) {
    text-align: center;
  }
  @media (max-width: 478px) {
    padding-top: 30px;

    padding-bottom: 0;
  }
  @media (max-width: 431px) {
    padding-top: 20px;

    padding-bottom: 0;
  }
`

const LogoListWrapper = styled.div`

 
  margin-top: -20px;
  @media (max-width: 770px) {
    margin-top: -100px;

  }
  @media (max-width: 616px) {
    margin-top: 0;
  }
  @media (max-width: 616px) {
    margin-top: 0;
  }
`

const LogoListContainer = styled.div`
  padding: 0 ${LAYOUT.gutterPage}px;
  box-sizing: border-box;
  width: 100%;
  max-width: ${LAYOUT.pageWidth}px;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: ${BREAKPOINTS.medUp}px) {
    padding: 0 ${LAYOUT.gutterPage / 2}px;
  }
  @media (max-width: ${BREAKPOINTS.sml}px) {
    padding: 0 ${LAYOUT.gutterPageMobile}px;
  }
  @media (max-width: 478px) {
    margin-top: 30px;
  }
`


/**
 * Displays clickable popular search terms, visually styled as tags.
 * Fetches data from the Site data type in the CMS to populate these popular search terms.
 */
const HeroSecondary = (props) => {
  
  return (
    <Wrapper>
      <SecondaryHeadingContainer flex={true} width={12}>
      {props.title && <SecondaryHeading>{props.title}</SecondaryHeading>}
      {props.body && <SecondaryBody>{props.body}</SecondaryBody>}
      </SecondaryHeadingContainer>
      {props.partners && 
        <LogoListWrapper>
        <LogoListContainer>
          <LogoGrid
            ctaDestination={props.partnersCtaDestination}
            ctaText={props.partnersCtaText}
            logos={props.partners}

          />
        </LogoListContainer>
        </LogoListWrapper>
      }
      
    </Wrapper>
  )
}

HeroSecondary.propTypes = {
  /** Optional heading */
  title: PropTypes.string,
  body: PropTypes.string,
}


export default HeroSecondary