import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import IconLinkArrowSvg from '../images/icons/link-arrow.svg'
import { BREAKPOINTS, ANIMATION, LAYOUT } from '../styles/cssVariables'
import { isAbsoluteUrl } from '../utils/utils'
import Container from './layout/container'


const HeroWrapper = styled.section`
background: linear-gradient(180deg, #006163 0%, #006163 0%, #006163 0.01%, #1C7876 0.02%, #006163 0.03%, #006163 0.04%, rgba(0, 97, 99, 0.45) 100%);
  padding-top: 110px;
  padding-bottom: 20px;
  @media (max-width: 1300px) {
    padding-bottom: 10px;  

  }


  }
  @media (max-width: ${BREAKPOINTS.med}px) {
    padding-top: 50px;

  }
  @media (max-width: ${BREAKPOINTS.sml}px) {
    padding-top: 30px;

  }
`

const HeroBody = styled.p`
  color: var(--color-white-85);
`




const HeroTitle = styled.h1`
    margin-bottom: 25px;
    color: var(--color-white);
   
`



const CTAButtonNoImage = styled.a`
  width: fit-content;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  border: 1px solid var(--color-white);
  cursor: pointer;
  padding: 1.25em 2.25em;
  border-radius: 4px;
  margin-top: 25px;
  margin-left: auto;
  margin-right: auto;
  background-color: var(--color-white) !important;
  color: var(--color-skobeloff) !important;
  transition: color ${ANIMATION.speedMedium} ease,
  background-color ${ANIMATION.speedMedium} ease;

  &:hover {
    color: var(--color-white) !important;
    background-color: transparent !important;  
  }

  @media (max-width: ${BREAKPOINTS.lrg}px) {
    padding: 1em 1.5em;
  }
`
const HeroContainerNoImage = styled(Container)`
  display: flex;
  
  @media (max-width: 1300px) {
    display: unset !important;
  }
`

const CTAButtonGatsbyLinkNoImage = styled(Link)`
  width: fit-content;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  box-sizing: border-box;
  border: 1px solid var(--color-white);
  cursor: pointer;
  padding: 1.25em 2.25em;
  border-radius: 4px;
  margin-top: 25px;
 margin-left: auto;
  margin-right: auto;
  background-color: var(--color-white) !important;
  color: var(--color-skobeloff) !important;

  transition: color ${ANIMATION.speedMedium} ease,
  background-color ${ANIMATION.speedMedium} ease;

  &:hover {
    color: var(--color-white) !important;
    background-color: transparent !important;  
  }

  @media (max-width: ${BREAKPOINTS.lrg}px) {
    padding: 1em 1.5em;
  }

  
`

const HeroPanelNoImage = styled.div`
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  width: 70%;
  margin-bottom: -20px;
  @media (max-width: ${BREAKPOINTS.medUp}px) {
    width: 80%;
  }
`


const Arrow = styled(IconLinkArrowSvg)`
  margin-left: 0.4em;
`


const HeroHome = (props) => {
  return (
    <>

     <HeroWrapper>
        <HeroContainerNoImage flex={true} width={12}>
          <HeroPanelNoImage>
            <HeroTitle>{props.heading}</HeroTitle>
            <HeroBody>{props.subheading}</HeroBody>
            {props.ctaText && (
              <>
                {isAbsoluteUrl(props.ctaDestination) ? (
                  <CTAButtonNoImage
                    href={props.ctaDestination}
                    target="__blank"
                    rel="nofollow"
                  >
                    {props.ctaText}
                    <Arrow />
                  </CTAButtonNoImage>
                ) : (
                  <CTAButtonGatsbyLinkNoImage to={props.ctaDestination}>
                    {props.ctaText}
                    <Arrow />
                  </CTAButtonGatsbyLinkNoImage>
                )}   
              </>
          )}
          </HeroPanelNoImage>
          
        </HeroContainerNoImage>
      </HeroWrapper>
  </>

  
  )
}



export default HeroHome
