import React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import get from 'lodash/get'
import styled from 'styled-components'
import Layout from '../components/layout/layout'
import Container from '../components/layout/container'
import HeroSecondary from '../components/ui-kit/heroSecondaryHome'
import InfoCard from '../components/ui-kit/infoCard'
import FeatureList from '../components/featureList'
import { BREAKPOINTS, LAYOUT } from '../styles/cssVariables'
import SEO from '../components/layout/seo'
import HeroHome from '../components/heroHome'



const InfoCardTitleContainer = styled(Container)`
  padding-bottom: 80px;

  @media (max-width: ${BREAKPOINTS.med}px) {
    padding-bottom: 32px;

    > div {
      width: 90%;
    }
  }
`

const InfoCardTitle = styled.h2`
  text-align: center;

`

const InfoCardContainer = styled(Container)`
  padding-bottom: 300px;

  > div {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px 30px;
  }

  @media (max-width: ${BREAKPOINTS.medUp}px) {
    > div {
      grid-template-columns: 1fr;
    }
  }
  @media (max-width: ${BREAKPOINTS.med}px) {
    padding-bottom: 250px;
  }

  @media (max-width: ${BREAKPOINTS.sml}px) {
    padding-bottom: 180px;
  }
`

const FeatureListWrapper = styled.section`
  margin-bottom: 200px;
  @media (max-width: ${BREAKPOINTS.med}px) {
    margin-bottom: 175px;
  }
  @media (max-width: ${BREAKPOINTS.tbl}px) {
    margin-bottom: 150px;
  }
  @media (max-width: ${BREAKPOINTS.sml}px) {
    margin-bottom: 100px;
  }
  > div div div div div picture img {
    border-radius: 4px;
  }
`



const FeatureListTitle = styled.h2`
  margin-bottom: 42px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  width: 70%;
  @media (max-width: ${BREAKPOINTS.medUp}px) {
    width: 100%;
  }


`


class RootIndex extends React.Component {
  render() {
    const heroHeading = get(this, 'props.data.contentfulPageHome.hero.heading')
    const heroSubheading = get(
      this,
      'props.data.contentfulPageHome.hero.subheading'
    )
    const heroCtaText = get(this, 'props.data.contentfulPageHome.hero.ctaText')
    const heroCtaDestination = get(
      this,
      'props.data.contentfulPageHome.hero.ctaDestination'
    )
    const heroSecondaryHeading = get(
      this,
      'props.data.contentfulPageHome.heroSecondaryHeading'
    )
    const heroSecondaryBody = get(
      this,
      'props.data.contentfulPageHome.heroSecondaryBody.heroSecondaryBody'
    )
    const openGraphImage = get(this, 'props.data.contentfulPageHome.openGraphImage')
    const infoCardsTitle = get(
      this,
      'props.data.contentfulPageHome.infoCardsTitle'
    )
    const howCanWeHelpInfoCards = get(
      this,
      'props.data.contentfulPageHome.howCanWeHelpInfoCards'
    )

    const featureListLayout = get(
      this,
      'props.data.contentfulPageHome.features.layout'
    )
    const featureListHeading = get(
      this,
      'props.data.contentfulPageHome.features.title'
    )
    const featureListImage = get(
      this,
      'props.data.contentfulPageHome.features.image'
    )
    const featureListCards = get(
      this,
      'props.data.contentfulPageHome.features.featureCards'
    )
    const featureListCtaText = get(
      this,
      'props.data.contentfulPageHome.features.ctaText'
    )
    const featureListCtaDestination = get(
      this,
      'props.data.contentfulPageHome.features.ctaDestination'
    )
    const secondFeatureListLayout = get(
      this,
      'props.data.contentfulPageHome.secondFeature.layout'
    )
    const secondFeatureListHeading = get(
      this,
      'props.data.contentfulPageHome.secondFeature.title'
    )
    const secondFeatureListImage = get(
      this,
      'props.data.contentfulPageHome.secondFeature.image'
    )
    const secondFeatureListCards = get(
      this,
      'props.data.contentfulPageHome.secondFeature.featureCards'
    )
    const secondFeatureListCtaText = get(
      this,
      'props.data.contentfulPageHome.secondFeature.ctaText'
    )
    const secondFeatureListCtaDestination = get(
      this,
      'props.data.contentfulPageHome.secondFeature.ctaDestination'
    )

    const partners = get(this, 'props.data.contentfulPageHome.supportersSecondaryHero.logos')
  

    const partnersCtaText = get(
      this,
      'props.data.contentfulPageHome.supportersSecondaryHero.ctaText'
    )
    const partnersCtaDestination = get(
      this,
      'props.data.contentfulPageHome.supportersSecondaryHero.ctaDestination'
    )

    const seoTitle = get(this, 'props.data.contentfulPageHome.seoTitle')
    const seoDescription = get(this, 'props.data.contentfulPageHome.seoDescription')
    
// console.log(openGraphImage);
  return (
      <Layout location={this.props.location}>
        <SEO
          pageTitle={seoTitle}
          seoDescription={seoDescription}
          // ogImage={openGraphImage.gatsbyImageData.images.fallback.srcSet}
        />

      <HeroHome
        heading={heroHeading}
        subheading={heroSubheading}
        ctaText={heroCtaText}
        ctaDestination={heroCtaDestination}
      />

        <HeroSecondary title={heroSecondaryHeading} body={heroSecondaryBody} partnersCtaDestination={partnersCtaDestination} partnersCtaText={partnersCtaText} partners={partners} />
   
        <FeatureListWrapper>
          <Container width={12}>
            <FeatureListTitle>
                {featureListHeading}
            </FeatureListTitle>

              <FeatureList
              ctaText={featureListCtaText}
              ctaDestination={featureListCtaDestination}
              featureCardArr={featureListCards.map((card) => [
                card.heading,
                card.body.body,
              ])}
              layout={featureListLayout}
              image={featureListImage}
            />
  
            
          </Container>
        </FeatureListWrapper>

        <FeatureListWrapper>
          <Container width={12}>
          <FeatureListTitle>
                {secondFeatureListHeading}
            </FeatureListTitle>
            <FeatureList
              ctaText={secondFeatureListCtaText}
              ctaDestination={secondFeatureListCtaDestination}
              featureCardArr={secondFeatureListCards.map((card) => [
                card.heading,
                card.body.body,
              ])}
              layout={secondFeatureListLayout}
              image={secondFeatureListImage}
            />
          </Container>
        </FeatureListWrapper>

        <section>
          <InfoCardTitleContainer align="center" width={8}>
            <InfoCardTitle>{infoCardsTitle}</InfoCardTitle>
          </InfoCardTitleContainer>
          <InfoCardContainer width={12}>
            <InfoCard
              ctaDestination={howCanWeHelpInfoCards[0].ctaDestination}
              ctaText={howCanWeHelpInfoCards[0].ctaText}
              heading={howCanWeHelpInfoCards[0].heading}
              icon={howCanWeHelpInfoCards[0].icon}
              body={howCanWeHelpInfoCards[0].body.body}
            />
            <InfoCard
              ctaDestination={howCanWeHelpInfoCards[1].ctaDestination}
              ctaText={howCanWeHelpInfoCards[1].ctaText}
              heading={howCanWeHelpInfoCards[1].heading}
              icon={howCanWeHelpInfoCards[1].icon}
              body={howCanWeHelpInfoCards[1].body.body}
            />
            <InfoCard
              ctaDestination={howCanWeHelpInfoCards[2].ctaDestination}
              ctaText={howCanWeHelpInfoCards[2].ctaText}
              heading={howCanWeHelpInfoCards[2].heading}
              icon={howCanWeHelpInfoCards[2].icon}
              body={howCanWeHelpInfoCards[2].body.body}
            />
          </InfoCardContainer>
        </section>


        

      </Layout>
    )
  }
}

export default RootIndex

export const homeQuery = graphql`
  query HomeQuery {
    
    contentfulPageHome {
      seoTitle
      seoDescription
      openGraphImage {
        title
        gatsbyImageData(layout: FULL_WIDTH, quality: 85)
      }
      hero {
        heading
        subheading
        
        ctaText
        ctaDestination
      }
      heroSecondaryHeading
      heroSecondaryBody {
        heroSecondaryBody
      }
      supportersSecondaryHero {
      
        logos {
          id
          title
          gatsbyImageData(layout: FULL_WIDTH)
          description
        }
        ctaText
        ctaDestination
      }
      infoCardsTitle
      howCanWeHelpInfoCards {
        icon {
          file {
            fileName
            url
          }
        }
        heading
        body {
          body
        }
        ctaText
        ctaDestination
        layout
      }
      features {
        layout
        image {
          title
          gatsbyImageData(layout: FULL_WIDTH)
        }
        title
        featureCards {
          heading
          body {
            body
          }
        }
        ctaText
        ctaDestination
      }
      secondFeature {
        layout
        image {
          title
          gatsbyImageData(layout: FULL_WIDTH)
        }
        title
        featureCards {
          heading
          body {
            body
          }
        }
        ctaText
        ctaDestination
      }
    }
    contentfulSite {
      prefooterSitewide {
        body {
          body
        }
        ctaText
        ctaDestination
        title
      }
  
      
    }
  }
`
